<template>
	<div class="ysm-order-seven">
		
				<div class="left" align="left">
					<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px" style="vertical-align: middle;" >
					<div>
						<h3>上传患者面相照片</h3>
						<div align="left" style="margin-bottom: 0.625rem;">请在此处上传患者头部照片。</div>
						<div align="left" >您可以在<a href="./mx.pdf" target="_blank"  style="color: #af9f73;font-weight: bold;text-decoration: underline;">此处</a>找到有关图片的示例。</div>
						<div>
							<el-form :model="ruleForm"  ref="ruleForm" :rules="rules">
								<el-form-item prop="chengxiangDate" label="成像日期">
								  <el-date-picker :picker-options="filerData.pickerOptions" type="date" placeholder="成像日期" v-model="ruleForm.chengxiangDate" style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>		     
								</el-form-item>
							</el-form>
							
						</div>
						<div align="left" style="margin-bottom: 0.625rem;"><span style="font-weight: bold;">注意</span>：图像需近30天内</div>
						<div align="left" style="margin-bottom: 0.625rem;"><span style="font-weight: bold;">有效格式</span>：.jpg、.jpeg、.png、.tif、.tiff</div>
						<div align="left" style="margin-bottom: 0.625rem;"><span style="font-weight: bold;">最小文件大小</span>：500KB</div>
						<div align="left" ><span style="font-weight: bold;">最大文件大小</span>：11MB</div>
					</div>
				</div>
				
			
			
				<div class="right">
					<!-- 请上传正面无笑容 -->
					<div class="shanghe"  style="display: inline-block;margin-right: 0.625rem;">
						<div style="position: relative;left: -4.8rem;font-size: 0.875rem;">请上传正面无笑容<span style="color: red;">*</span></div>
						<el-upload
						  class="upload-demo"
						  drag
						  action=''
						  ref="upload"
						  :http-request="changeFile"
						  multiple
						  accept=".jpg,.png"
						  :before-upload="beforeAvatarUpload"
						  >
						   <img v-show="zwVisiable" class="zw" :src="zwPicUrl" style="width: 100%; height: 100%;" />
						   <img src="../../assets/mianxiang/zhengwuxiao.svg" width="150" height="150" />
						  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						  <div class="el-upload__tip" slot="tip"></div>
						</el-upload>
						
					</div>
					<!-- 请上传斜45度无笑容 -->
					<div class="xiahe"  style="display: inline-block;">
					<div style="margin-top: 0.3125rem;font-size: 0.875rem;position: relative;left: -4.25rem;">请上传斜45度无笑容<span style="color: red;">*</span></div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile1"
					  >
					   <img v-show="xwVisiable" class="xw" :src="xwPicUrl" style="width: 100%; height: 100%;" />
					  <img src="../../assets/mianxiang/xie.svg" width="150" height="150" />
					  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<br />
					<!-- 侧面无笑容 -->
					<div class="yaohe" style="display: inline-block;margin-right: 0.625rem;">
					<div style="margin-top: 0.3125rem;font-size: 0.875rem;position: relative;left: -5rem;">请上传侧面无笑容<span style="color: red;">*</span></div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile2"
					  >
					    <img v-show="cwVisiable" class="cw" :src="cwPicUrl" style="width: 100%; height: 100%;" />
					   <img src="../../assets/mianxiang/ce.svg" width="150" height="150" />
					  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<!-- 正面微笑-->
					<div class="yaohe"  style="display: inline-block;">
					<div style="margin-top: 0.3125rem;font-size: 0.875rem;position: relative;left: -5rem;">请上传正面微笑<span style="color: red;">*</span></div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile3"
					  >
					   <img v-show="zxVisiable" class="zx" :src="zxPicUrl" style="width: 100%; height: 100%;" />
					  <img src="../../assets/mianxiang/zhengxiao.svg" width="150" height="150" />
					  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<div align="right">
						<el-button @click="goOrderFive" class="back"  type="button">返回</el-button>
						<el-button @click="nextStepKN" class="nextStep" type="button">下一步</el-button>
					</div>
				</div>
			
	</div>
</template>

<script>
	export default{
		data:function(){
			return{
				filerData: {
				  pickerOptions: {
				    disabledDate(time) {
				      return time.getTime() > Date.now() - 8.64e6
				    }
				  },
				},
				zwVisiable:false,
				xwVisiable:false,
				cwVisiable:false,
				zxVisiable:false,
				zwPicUrl:'',
				xwPicUrl:'',
				cwPicUrl:'',
				zxPicUrl:'',
				ruleForm:{
					chengxiangDate:'',
					oid:'',
					
				},
				rules:{
					chengxiangDate:[
						{ required: true, message: '请选择日期', trigger: 'blur' },
						 { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
					]
				}
			}
		},
		methods:{
			
			beforeAvatarUpload(file) {
			    console.log('file', file)
			    let types = ['image/jpeg', 'image/jpg', 'image/png'];
			    const isImage = types.includes(file.type);
			    if (!isImage) {
			        this.$message.error('上传图片只能是 .jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff 格式!');
			      }else{
			        //this.uploadToken.key=`upload_pic_${Date.parse(new Date())}${file.name}`
			      }
				  const isLtSize = file.size / 1024 / 1024 < 5;
				   if (!isLtSize) {
				            this.$message.error('上传图片大小不能超过 1MB!');
				       }
			    },
				
				//上传正面无笑容
			changeFile(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'mxZheng')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
				   		
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.zwVisiable=true;
							this.zwPicUrl=res.data.data.picUrl;
							
						}
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//上传斜45度无笑容
			changeFile1(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'mxXie')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.xwVisiable=true;
							this.xwPicUrl=res.data.data.picUrl;
						}
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//上传侧面无笑容
			changeFile2(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'mxCe')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.cwVisiable=true;
							this.cwPicUrl=res.data.data.picUrl;
						}
				   		//document.getElementById("i").setAttribute("src",res.data.data.picUrl);
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//正面微笑
			changeFile3(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'mxXiao')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.zxVisiable=true;
							this.zxPicUrl=res.data.data.picUrl;
						}
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			
			//下一步
			nextStepKN(){
				//上传日期
				 let formData1 = new FormData()
				formData1.append('date',this.ruleForm.chengxiangDate)// 传文件
				formData1.append('oid', this.ruleForm.oid)
				formData1.append('type',"mxDate")
			
				this.$axios.post("client/order/uploadResourceDate",formData1,{
					headers:{
						"Content-type":"application/json"
					}
				}).then(res=>{
					console.log(res);
					if(res.data.code==200){
						this.$message.success("成功");
						this.$store.commit("setYsmStepIndex",9);
						this.$router.push("/ysmmain/ysmorder/ysmordereight");
					}
				}).catch(err=>{
					this.$message.error("添加失败");
				});
				
				
			},
			//返回
			goOrderFive(){
				this.$store.commit("setYsmStepIndex",6);
				this.$router.push("/ysmmain/ysmorder/ysmordersix")
			}
		},
		mounted:function(){
			this.$store.commit('setStepIndex', 7);
			//设置默认地址
			sessionStorage.setItem("location","/ysmmain/ysmorder/ysmorderseven")
			//获取当前添加的订单oid
			//获取oid
			// let oid=sessionStorage.getItem("oid");
			// if(oid==null){
			// 	this.$alert("请填写患者信息","提示");
			// 	this.$router.push("/addorder/orderone");
			// }else{
			// 	this.ruleForm.oid=oid;
			// }
			document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")	
			//设置返回路径
			this.$store.commit("setBackLocation","/ysmmain/ysmorder/ysmordersix");
		},
		created:function(){
				//如果是编辑订单要获取oid的值
				let oid=sessionStorage.getItem("oid");
			
				if(oid!="undefined"&&oid!=''&&oid!=null){
					this.ruleForm.oid=oid;
					this.$axios.get("/client/order/getOrderInfo",{
						params:{
							oid:this.ruleForm.oid
						}
					}).then(res=>{
						console.log(res.data.data.resource);
						let mxDate=res.data.data.resource.mxDate;//口内日期
						let xiao=res.data.data.resource.mxXiao;//笑
						let xie=res.data.data.resource.mxXie;//斜
						let zheng=res.data.data.resource.mxZheng;//正
						let ce=res.data.data.resource.mxCe;//侧
						//日期回显
						if(mxDate!=null){
							this.ruleForm.chengxiangDate=mxDate;
						}
						//回显笑
						if(xiao!=null){
							this.zxVisiable=true;
							document.getElementsByClassName("zx")[0].setAttribute("src",xiao);
						}
						//回显斜
						if(xie!=null){
							this.xwVisiable=true;
							document.getElementsByClassName("xw")[0].setAttribute("src",xie);
						}
						//回显正
						if(zheng!=null){
							this.zwVisiable=true;
							document.getElementsByClassName("zw")[0].setAttribute("src",zheng);
						}
						//回显侧
						if(ce!=null){
							this.cwVisiable=true;
							document.getElementsByClassName("cw")[0].setAttribute("src",ce);
						}
						
					}).catch(err=>{
						console.log(err);
					});
				}else{
					this.$alert('请填写患者信息', '提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.$router.push("/ysmmain/ysmorder/ysmorderone");
						}
					});
					
				}
		},
	}
</script>

<style scoped="scoped">
	.ysm-order-seven{
		width: 80%;
		display: flex;
		margin: 0px auto;
		
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
		justify-content: space-around;
	}
	
	h3{
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.left{
		display: flex;
		width: 25rem;
		position: relative;
		justify-content: flex-start;
	}
	.right{
		/* position: relative;
		left: 1.25rem; */
		width:560px;
	}
	
	.btnNextKouSao{
		height: 1.875rem;
		border-radius: 0.625rem;
		background-image: linear-gradient(to right, white, #28D9D5, #2B9BB3);
		border: none;
		margin-bottom: 1.25rem;
	}
	.xy{
		display: none;
	}
	/* 按钮 */
	.nextStep{
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}
	.back{
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #af9f73 !important;
		color: #af9f73 !important;
	}
</style>
